
  .header-scroll {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
  }

  .active {
    color:#183274;
    border-bottom: 3px solid #183274;
  
  }



  /* Header.css */

.dropdown {
  position: relative;
  /* display: inline-block; */
}


.dropdown-menu {
  
  width: 300px;
  display: none;
  position: absolute;
  top: 100%;
  left: -50px;
  background-color: #f9f9f9;
  padding: 12px;
  border-radius: 4px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
  color: red;


}

.dropdown-menu li {
  padding: 10px 12px;
}

.dropdown-menu li:hover {
  background-color: #eaeaea;
}
