/* CSS Styles for the spinner and alert */
/* .spinner {
  display: inline-block;
  border: 4px solid rgba(41, 130, 219, 0.3);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-right: 2px;
} */

.spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top-color: #3498db;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.alert {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  margin-bottom: 10px;
}



/* css style for modal box  */

.edit-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
}

.edit-modal.show {
  opacity: 1;
  pointer-events: auto;
}

.edit-modal .modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  max-width: 1400px;
}

.edit-modal .modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.edit-modal .modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
}

.edit-modal .modal-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.edit-modal .modal-button {
  padding: 10px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 3px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.edit-modal .modal-button.cancel {
  background-color: #ccc;
}

.edit-modal .modal-button:hover {
  background-color: #0056b3;
}