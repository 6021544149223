$base-duration: 500ms;

// Colors
$color-1: #2c3e50;
$color-2: #1abc9c;
$color-3: #2ecc71;

// Breakpoints
$sm: new-breakpoint(min-width 320px);
$med: new-breakpoint(min-width 768px);
$lg: new-breakpoint(min-width 1024px);

*,
*:before,
*:after {
	// box-sizing: border-box;
	outline: none;
}

// html {
// 	font-family: 'Roboto', sans-serif;
// 	font-size: 16px;
// 	font-smooth: auto;
// 	font-weight: 300;
// 	line-height: 1.5;
// 	color: #444;
// }

// body {
// 	position: relative;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	width: 100%;
// 	height: 100vh;
// }

figure {
	position: relative;
	width: 400px;
	min-width: 375px;
	height: 400px;
	border-radius: 10px;
	box-shadow: 
		0 1px 3px rgba(0,0,0,0.12), 
		0 1px 2px rgba(0,0,0,0.24);
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	overflow: hidden;
	&:hover {
		box-shadow: 
			0 14px 28px rgba(0,0,0,0.25), 
			0 10px 10px rgba(0,0,0,0.22);
		h1 {
			opacity: 0;
			transform: scale(0.7);
		}
		img {
			transform: scale(1.25);
		}
		figcaption {
			bottom: 0;
		}
	}
	h1 {
		position: absolute;
		top: 50px;
		left: 20px;
		margin: 0;
		padding: 0;
		color: white;
		font-size: 60px;
		font-weight: 100;
		line-height: 1;
		opacity: 1;
		transform: scale(1);
		transition: 0.25s ease;
		z-index: 999;
	}
	img {
		height: 100%;
        width: 100%;
		transition: 0.25s;
	}
	figcaption {
		position: absolute;
		bottom: -58%;
		left: 0;
		width: 100%;
		margin: 0;
		padding: 40px;
		background-color: rgba(rgb(4, 24, 98),0.9);
		box-shadow: 0 0 20px rgba(black,0.5);
		color: white;
		line-height: 1;
		transition: 0.25s;
		h3 {
			margin: 0 0 30px;
			padding: 0px;
			font-size: 18px;
		}
		p {
			font-size: 14px;
			line-height: 1.75;
		}
		button {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 0 0;
			padding: 10px 30px;
			background-color: $color-2;
			border: none;
			border-radius: 5px;
			color: white;
			font-size: 14px;
			cursor: pointer;
		}
	}
}
