#animated-border {
    position: relative;
  }
  
  #animated-border::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #fc8181; /* Set your desired border color */
    transition: width 0.3s ease;
  }
  
  #animated-border:hover::after {
    width: 100%;
  }
  