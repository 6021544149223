.cards {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .cards_item {
    display: flex;
    padding: 1rem;
  }
  
  .card_image {
    position: relative;
    max-height: 250px;
  }
  
  .card_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .card_price {
    position: absolute;
    bottom: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 0.25rem;
    background-color: #c89b3f;
    font-size: 18px;
    font-weight: 700;
  }
  
  .card_price span {
    font-size: 12px;
    margin-top: -2px;
  }
  
  .note {
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 4px 8px;
    border-radius: 0.25rem;
    background-color: #c89b3f;
    font-size: 14px;
    font-weight: 700;
  }
  
  @media (min-width: 40rem) {
    .cards_item {
      width: 50%;
    }
  }
  
  @media (min-width: 56rem) {
    .cards_item {
      width: 33.3333%;
    }
  }
  
  .card {
    background-color: white;
    border-radius: 0.25rem;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .card_content {
    position: relative;
    padding: 16px 12px 32px 24px;
    margin: 16px 8px 8px 0;
    max-height: 290px;
    overflow-y: scroll;
  }
  
  .card_content::-webkit-scrollbar {
    width: 8px;
  }
  
  .card_content::-webkit-scrollbar-track {
    box-shadow: 0;
    border-radius: 0;
  }
  
  .card_content::-webkit-scrollbar-thumb {
    background: #c89b3f;
    border-radius: 15px;
  }
  
  .card_title {
    position: relative;
    margin: 0 0 24px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  
  .card_title::after {
    position: absolute;
    display: block;
    width: 50px;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #c89b3f;
    content: "";
  }
  
  hr {
    margin: 24px auto;
    width: 50px;
    border-top: 2px solid #c89b3f;
  }
  
  .card_text p {
    margin: 0 0 24px;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .card_text p:last-child {
    margin: 0;
  }
  
  
  .count-boxes {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .count-box {
    width: 380px;
    height: 150px;
    margin-left: 20px;
    border: 1px solid rgb(236, 167, 103);
    display: flex;
    text-align: center;
    color: black;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    box-shadow: 1px 3px #e69a53;
    transition: transform .2s;
  }
  
  .count-box img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  
  .count-box:hover {
    color: rgb(204, 103, 9);
    -ms-transform: scale(1.1);
    /* IE 9 */
    -webkit-transform: scale(1.1);
    /* Safari 3-8 */
    transform: scale(1.1);
  }
  
  .count-title {
    width: 400px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(2, 47, 131);
  
  }
  
  .count-title:hover {
    color: rgb(12, 175, 216);
  }
  
  .features {
    width: 100%;
    height: 40vh;
    margin-top: 5%;
  }
  
  .featureHead {
    margin-bottom: 50px;
  }
  
  .featureTitle {
    font-size: 12px !important;
    color: #012e26f1;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .featureMain {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 95px;
    width: 75rem;
    height: 190px;
    border-radius: 10px;
    box-shadow: 0px 2px 20px rgba(85, 85, 85, 0.548);
    background: none;
  }
  
  .featureBox {
    width: 30rem;
    height: 190px;
    border-right: 1px solid rgb(189, 188, 188);
    background: #fff;
  }
  
  .featureBoxFirst {
    width: 15.5rem;
    height: 190px;
    border-right: 1px solid rgb(189, 188, 188);
    box-shadow: 0px 0px 20px rgb(248, 244, 244);
    border-radius: 10px 0px 0px 10px;
  
  }
  
  .featureBoxLast {
    width: 15.5rem;
    height: 190px;
    border-left: 1px solid rgb(253, 251, 251);
    border-radius: 0 10px 10px 0px;
    box-shadow: 0px 0px 20px rgb(248, 244, 244);
    background: #fff;
    box-sizing: border-box;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  .mt-4 {
    margin-top: 1.5rem !important;
  }
  
  .pt-3 {
    padding-top: 1rem !important;
  }
  
  .featureBox {
    width: 15rem;
    height: 190px;
    border-right: 1px solid rgb(189, 188, 188);
    background: #fff;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  .pt-3 {
    padding-top: 1rem !important;
  }
  
  
  .featureBoxFirst {
    width: 15.5rem;
    height: 190px;
    border-right: 1px solid rgb(189, 188, 188);
    box-shadow: 0px 0px 20px rgb(248 244 244);
    border-radius: 10px 0px 0px 10px;
    background: #fff;
    box-sizing: border-box;
  }
  
  .pt-3 {
    padding-top: 1rem !important;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
  }
  
  .pt-4 {
    padding-top: 1.5rem !important;
  }
  
  .mb-3 {
    margin-bottom: 1rem !important;
  }
  
  .align-items-center {
    align-items: center !important;
  }
  
  .justify-content-center {
    justify-content: center !important;
  }
  
  .d-flex {
    display: flex !important;
  }
  
  .fa,
  .fas {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 60px;
  }
  
  .far {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 60px;
  }
  
  .fa-handshake:before {
    content: "\f2b5";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 60px;
  }
  
  .featureMain {
    height: auto !important;
  }
  
  .featureMain {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 95px;
    width: 75rem;
    height: 190px;
    border-radius: 10px;
    box-shadow: 0px 2px 20px rgb(85 85 85 / 55%);
    background: none;
  }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 10px;
    background-color: #ece7e7;
    padding: 10px;
    height: 250px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 5em;
    border-radius: 20px;
  }
  
  .grid-container .item1 {
    background-color: rgba(255, 253, 253, 0.8);
    padding: 20px 0;
    text-align: center;
    border-radius: 20px;
    font-size: 15px;
  }
  
  .grid-container .text1 {
    color: #a54416;
    margin-top: 10%;
  }
  
  * {
    box-sizing: border-box
  }
  
  body {
    font-family: Verdana, sans-serif;
    margin: 0
  }
  
  .mySlides {
    height: 100px;
    display: flex;
  }
  
  img {
    vertical-align: middle;
  }
  
  /* Slideshow container */
  .slideshow-container {
    max-width: 1000px;
    position: relative;
    margin: auto;
  }
  
  /* Next & previous buttons */
  .prev,
  .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    padding: 16px;
    margin-top: -22px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
  }
  
  /* Position the "next button" to the right */
  .next {
    right: 0;
    border-radius: 3px 0 0 3px;
  }
  
  /* On hover, add a black background color with a little bit see-through */
  .prev:hover,
  .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  /* Caption text */
  .text {
    color: #f2f2f2;
    font-size: 15px;
    padding: 8px 12px;
    position: absolute;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }
  
  /* Number text (1/3 etc) */
  .numbertext {
    color: #f2f2f2;
    font-size: 12px;
    padding: 8px 12px;
    position: absolute;
    top: 0;
  }


  .video-player {
    position: relative;
    /* height: 300px; */
    /* padding: px; */
  }
  
  .play-pause-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .play-pause-button:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 26px solid white;
  }
  
  .play-pause-button.pause:before {
    display: none;
  }
  
  .play-pause-button:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 16px;
    height: 32px;
    background-color: white;
    display: none;
  }
  
  /* .play-pause-button.pause:after {
    display: block;
  }
   */


