

  .gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: opacity 0.3s;
  }
  
  .image-container:hover .gallery-image {
    filter: brightness(50%);
    transition: 0.3s;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .image-container:hover .overlay {
    opacity: 1;
  }
  
  .zoom-icon {
  color: white;
  font-size: 24px;
  position: absolute;
}
  
  .zoom-icon:hover {
    color: gray;
  }
  